


















import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Inject, Prop } from "vue-property-decorator";

@Observer
@Component({
  components: {},
})
export default class BoxInfo extends Vue {
  @Prop({ default: "" }) boxId!: string;
}
